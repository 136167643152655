import React from 'react';
import App from '@/components/App';

const NotFoundPage = () => (
  <App language="zh-hk" defaultLanguage="zh-hk">
    <div>
      <h1>NOT FOUND</h1>
    </div>
  </App>
);

export default NotFoundPage;
